import React from 'react';
import styled from 'styled-components';
import { Box, Large, colors } from 'js-components';
import { Section, WrapperSolid } from '../Wrapper';

import Charles from './CustomerPhotos/charles.jpg';
import Zoey from './CustomerPhotos/zoey.jpg';
import Timothy from './CustomerPhotos/timothy.jpg';

const getCustomerPhoto = background => {
  switch (background) {
    default:
    case 'charles':
      return Charles;
    case 'zoey':
      return Zoey;
    case 'timothy':
      return Timothy;
  }
};

function Quote(props) {
  return (
    <WrapperSolid color="#222222">
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 2 / 3]}>
          <QuoteText as="p" color={colors.white}>
            {props.quoteText}
          </QuoteText>
          <QuoteName color={props.color} my="16pt">
            {props.quoteAttribution}
          </QuoteName>
          <QuoteImg color={props.color} src={getCustomerPhoto(props.customerPhoto)} />
        </Box>
      </Section>
    </WrapperSolid>
  );
}

Quote.defaultProps = {
  customerPhoto: 'charles',
  quoteAttribution: 'Charles Bergevin, Uber',
  quoteText:
    '"Truly builds a product for sales teams, and understand the details and intricacies that go into building a world class sales team. They know that data is king for sales ops, and the connection with salesforce is better than any competitive product I’ve used. They also invest the time to really get to know your business and live up to being a partner."',
  color: colors.accentLavender
};

export default Quote;

const QuoteText = styled(Large)`
  font-style: italic;
`;

const QuoteName = styled(Large)`
  font-weight: bold;
  text-transform: uppercase;
`;

const QuoteImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2pt solid ${props => props.color || colors.trulyBlue};
`;
