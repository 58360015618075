import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Pain-free Sales Calls</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Experience The New Digital Sales Floor
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Get Complete Deal Visibility With Better Analytics, AI-Powered A and The Best Activity Tracking
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>

    <Quote></Quote>

    <WrapperSolid>
      <Section flexDirection="column" py={['3rem', '5rem']}>
      <Box width={[1, 1/2]}>
        <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
        MAKE SALESFORCE THE TRUE SYSTEM OF RECORD
        </Title3>
        <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
        Automatically log your customer communication across all devices (calls, texts, emails, web meetings) to your CRM . 
        <br/><br/>
        Truly instantly matches activities, notes, transcriptions, and recordings to the right Contact, Account, and Opportunity records, so you can understand your funnel in real-time without any data cleanup in Excel or SQL.
        </Large>
      </Box>
      <Box width="3/4">
          <WidthImg src={SfOnLaptop} alt="Log Calls"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          MOVE DEALS FORWARD FASTER
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
          Keep your reps focused on the right deals and activities with Salesforce Analytics.  
          <br/><br/>
          Get real-time notifications about key conversations to ensure you can push deals to close faster.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={SfNotes} alt="Notes"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={TeamDashHalf} alt="Coaching"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          SCALE COACHING ACROSS YOUR TEAM
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Make it easy to find and share call recordings with powerful search, tagging, and commenting features. Track coaching activity and impact across your organization.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

  </Default>
)

export default SolutionsPage

